<template>
    <div>
        <div class="text-left text-xs bg-black text-white opacity-50 p-1">Content text block</div>
        <div class="bg-gray-200 mb-8 p-4">
            <h2 class="text-4xl text-black block font-bold">{{ getValue('title')}}</h2>
            <h4 class="text-xl text-gray-700 block font-bold">{{ getValue('subTitle')}}</h4>

            <div class="flex justify-center mt-4">
                <p v-if="getValue('textOne')" class="mx-2">
                    {{ getValue('textOne')}}
                </p>
                <p v-if="getValue('textTwo')" class="mx-2">
                    {{ getValue('textTwo')}}
                </p>
                <p v-if="getValue('textThree')" class="mx-2">
                    {{ getValue('textThree')}}
                </p>
                <p v-if="getValue('textFour')" class="mx-2">
                    {{ getValue('textFour')}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";

export default {
    mixins: [ Widget ],
}
</script>